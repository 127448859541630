
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_ACTIVITY_URL = BASE_API_URL+"activities"

@Component
export default class ActivitiesMixin extends Vue {

  getActivities(data: any, tag: string) {
    return this.$httpGetQuery(BASE_ACTIVITY_URL, tag,data);
  }

  destroyActivity(id: string|number, tag: string) {
    return this.$httpDelete(BASE_ACTIVITY_URL+"/"+id, tag);
  }

  createActivity(data: any, tag: string) {
    return this.$httpPost(BASE_ACTIVITY_URL, tag, data);
  }

  updateActivity(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_ACTIVITY_URL+"/"+id, tag, data);
  }
}
