





































import { Activity } from "@/models";
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
@Component({})
export default class ActivityItem extends Mixins() {
  @Prop({ required: true }) readonly activity!: Activity;
  @Prop({ default: true }) readonly deletable!: boolean;
  @Prop({ default: true }) readonly editable!: boolean;

  private item: Activity = this.activity;
  private open = false;
}
