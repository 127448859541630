











import { Vue, Component, Prop } from "vue-property-decorator";
import OrderStatusSelect from "@/components/OrderStatus/Select.vue";
import Activity from "@/models/Activity";

@Component({ components: { OrderStatusSelect } })
export default class UpdateOrderStatus extends Vue {
  @Prop({ required: true }) readonly activity!: Activity;

  private item: Activity = this.activity;

  get selected() {
    return this.item.meta.orderStatusId;
  }
  onSelected(data: any) {
    this.$emit("input", data);
  }
}
